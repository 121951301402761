@import "shariff-plus/dist/shariff.min.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --fc-daygrid-event-dot-width: 10px;
  --fc-today-bg-color: unset;
}

@layer base {
  html, body {
    height: auto;
    width: 100%;
    min-height: 100vh;
  }
}
@layer components {
  body.public-webshop .shariff,
  body[class*=partnershop-] .shariff {
    @apply tw-bg-transparent;
  }
  body.public-webshop .fc-view,
  body[class*=partnershop-] .fc-view {
    @apply tw-shadow-sm tw-rounded-xl tw-bg-white tw-overflow-hidden;
  }
  body.public-webshop .fc-view .fc-scrollgrid,
  body[class*=partnershop-] .fc-view .fc-scrollgrid {
    @apply tw-rounded-md;
  }
  body.public-webshop .fc-button.fc-button-primary,
  body[class*=partnershop-] .fc-button.fc-button-primary {
    @apply tw-shadow-md tw-btn tw-btn-primary;
  }
  body.public-webshop .fc-daygrid-dot-event,
  body[class*=partnershop-] .fc-daygrid-dot-event {
    @apply tw-flex tw-items-center tw-justify-center -tw-z-10;
  }
  body.public-webshop .fc-day,
  body[class*=partnershop-] .fc-day {
    @apply tw-cursor-pointer;
  }
  body.public-webshop .fc-daygrid-event-dot,
  body[class*=partnershop-] .fc-daygrid-event-dot {
    @apply tw-border-highlight;
  }
  body.public-webshop .fc-event-title,
  body[class*=partnershop-] .fc-event-title {
    @apply tw-hidden;
  }
  body.public-webshop .fc-h-event .fc-event-title-container,
  body[class*=partnershop-] .fc-h-event .fc-event-title-container {
    @apply tw-bg-highlight tw-text-highlightContrast;
  }
  body.public-webshop.page_events,
  body[class*=partnershop-].page_events {
    @apply tw-min-h-screen tw-flex tw-flex-col;
  }
  body.public-webshop.page_events #content,
  body[class*=partnershop-].page_events #content {
    @apply tw-flex-1 tw-flex tw-flex-col tw-w-full;
  }
  .tw-icon-link {
    @apply tw-link tw-flex tw-flex-row tw-items-center tw-gap-2;
  }
  [id^=switch-] .list:has(:checked) {
    @apply tw-btn-active;
  }
  [id^=switch-] .list:has(:checked) svg path {
    @apply tw-fill-highlightContrast;
  }
  [id^=switch-] .calendar:has(:checked) {
    @apply tw-btn-active;
  }
  [id^=switch-] .calendar:has(:checked) svg path {
    @apply tw-fill-highlightContrast;
  }
  [id^=switch-]:has(.calendar :checked) ~ .event-list-wrapper,
  [id^=switch-]:has(.calendar :checked) .sort {
    @apply tw-hidden;
  }
  [id^=switch-]:has(.list :checked) ~ .calendar-wrapper {
    @apply tw-hidden;
  }
}
.tw-btn-active {
  @apply tw-bg-highlight tw-text-highlightContrast tw-border-none;
}

.tw-btn.tw-btn-disabled {
  @apply tw-text-white tw-bg-wlec-inactive contrast-more:tw-text-black tw-opacity-100;
}

h1:has(.tw-divider:not(:empty)) {
  @apply tw-text-2xl tw-font-bold;
}

h2:has(.tw-divider:not(:empty)) {
  @apply tw-text-[1.25rem] tw-font-thin tw-tracking-wide;
}

h3:has(.tw-divider:not(:empty)) {
  @apply tw-text-xl tw-font-bold tw-tracking-wide;
}

h4:has(.tw-divider:not(:empty)) {
  @apply tw-text-xl tw-font-thin tw-tracking-wide;
}

h5:has(.tw-divider:not(:empty)) {
  @apply tw-text-lg tw-font-bold;
}

h6:has(.tw-divider:not(:empty)) {
  @apply tw-text-lg tw-font-thin tw-tracking-wide;
}

.tw-btn-neutral {
  @apply tw-bg-white tw-outline-white;
}

.tw-btn-primary {
  @apply tw-bg-highlight tw-text-highlightContrast tw-border-none tw-outline-highlight;
}

.tw-btn:hover {
  @apply hover:tw-bg-highlightLight tw-text-highlightContrast;
}

.tw-link {
  @apply tw-flex;
}

.tw-select {
  @apply tw-m-0;
}

body.page-purchase {
  /* COLORS */
  /* DIMENSIONS */
  /* Font Sizes */
  /* Media */
  /* Checkout */
  /* Modal */
  /* General Styles */
  margin: 0;
  padding: 0;
  background: #ebecee;
  font-family: "Helvetica", sans-serif;
  font-size: 13px;
  line-height: 1.2em;
  /* DESKTOP STYLES */
  /* MOBILE STYLES */
  /* MOBILE STYLES */
  /* Main Checkout Page Elements */
  /* Header */
  /* Media */
}
body.page-purchase * {
  box-sizing: border-box;
}
body.page-purchase span, body.page-purchase p {
  line-height: 1.2;
}
body.page-purchase p, body.page-purchase h1, body.page-purchase h2, body.page-purchase h3, body.page-purchase h4, body.page-purchase h5, body.page-purchase h6, body.page-purchase a {
  color: #000000;
  margin: 0;
}
body.page-purchase p {
  font-size: 13px;
}
body.page-purchase h1, body.page-purchase h2, body.page-purchase h3, body.page-purchase h4, body.page-purchase h5, body.page-purchase h6 {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: bold;
}
body.page-purchase .sub {
  font-size: 13px;
  color: #878988;
}
@media only screen and (max-width: 320px) {
  body.page-purchase .sub {
    width: 128px;
    word-wrap: break-word;
  }
}
@media only screen and (min-width: 321px) and (max-width: 380px) {
  body.page-purchase .sub {
    width: 168px;
    word-wrap: break-word;
  }
}
body.page-purchase .reservation-box-content .sub {
  width: 100%;
}
body.page-purchase #donation .sub {
  width: 100%;
}
body.page-purchase a {
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}
body.page-purchase a.sub {
  color: #878988;
  font-weight: normal;
  text-decoration: underline;
  width: auto;
}
body.page-purchase .text-right {
  text-align: right;
}
@font-face {
  font-family: "main-font";
  src: url("/fonts/main-font.eot");
  src: url("/fonts/main-font.eot?#iefix") format("embedded-opentype"), url("/fonts/main-font.woff") format("woff"), url("/fonts/main-font.ttf") format("truetype"), url("/fonts/main-font.svg#main-font") format("svg");
  font-weight: normal;
  font-style: normal;
}
body.page-purchase span.icon {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
}
body.page-purchase span.icon.right {
  float: right;
}
body.page-purchase .icon.icon-delete {
  top: 2px;
  width: 11px;
  height: 14px;
  background-image: url("/img/purchase/other/icon_trash.png");
}
body.page-purchase .icon.icon-info {
  top: 0px;
  width: 13px;
  height: 13px;
}
body.page-purchase .icon.icon-cog {
  top: 2px;
  width: 14px;
  height: 14px;
  background-image: url("/img/purchase/other/icon_cog.png");
}
body.page-purchase .icon.icon-cross {
  top: 3px;
  width: 13px;
  height: 13px;
  background-image: url("/img/purchase/other/icon_cross.png");
}
body.page-purchase [data-icon]:before {
  font-family: "main-font", sans-serif !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.page-purchase [class^=icon-]:before,
body.page-purchase [class*=" icon-"]:before {
  font-family: "main-font", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.page-purchase .icon-loading:before {
  content: "c";
}
body.page-purchase .icon-checkbox:before {
  content: "u";
}
body.page-purchase .icon-checkbox-checked:before {
  content: "v";
}
body.page-purchase .icon-download:before {
  content: "w";
}
body.page-purchase .icon-dropdown:before {
  content: "x";
}
body.page-purchase .icon-eye:before {
  content: "z";
}
body.page-purchase .icon-check:before {
  content: "C";
}
body.page-purchase .icon-info:before {
  content: "D";
}
body.page-purchase .icon-calendar:before {
  content: "E";
}
body.page-purchase .icon-map:before {
  content: "G";
}
body.page-purchase .icon-config:before {
  content: "H";
}
body.page-purchase .icon-uncheck:before {
  content: "I";
}
body.page-purchase .icon-plus:before {
  content: "K";
}
body.page-purchase .icon-minus:before {
  content: "L";
}
body.page-purchase #cart article, body.page-purchase #cart-mobile #cart-cartItems-mobile article {
  position: relative;
}
body.page-purchase #cart article .img-wrapper, body.page-purchase #cart-mobile #cart-cartItems-mobile article .img-wrapper {
  float: left;
  margin-right: 10px;
  width: 60px;
  min-height: 46px;
}
body.page-purchase #cart article .img-wrapper img, body.page-purchase #cart-mobile #cart-cartItems-mobile article .img-wrapper img {
  width: 60px;
}
body.page-purchase #cart article .price, body.page-purchase #cart-mobile #cart-cartItems-mobile article .price {
  font-weight: bold;
  font-size: 13px;
}
body.page-purchase #cart article .icon-delete, body.page-purchase #cart-mobile #cart-cartItems-mobile article .icon-delete {
  margin-left: 10px;
}
body.page-purchase #cart article .price-bar-spacer, body.page-purchase #cart-mobile #cart-cartItems-mobile article .price-bar-spacer {
  float: left;
  width: 100%;
  height: 20px;
}
body.page-purchase #cart article .price-bar-right, body.page-purchase #cart-mobile #cart-cartItems-mobile article .price-bar-right {
  position: absolute;
  right: 0;
}
body.page-purchase #cart article div .vt-details, body.page-purchase #cart-mobile #cart-cartItems-mobile article div .vt-details {
  float: left;
}
body.page-purchase #cart article .price-bar, body.page-purchase #cart-mobile #cart-cartItems-mobile article .price-bar {
  position: absolute;
  bottom: 0;
  right: 0;
  min-height: 46px;
}
body.page-purchase #cart article .price-option, body.page-purchase #cart-mobile #cart-cartItems-mobile article .price-option {
  cursor: pointer;
  margin-right: 10px;
}
body.page-purchase #cart article.special-item, body.page-purchase #cart-mobile #cart-cartItems-mobile article.special-item {
  display: table;
}
body.page-purchase #cart article.special-item .img-container, body.page-purchase #cart-mobile #cart-cartItems-mobile article.special-item .img-container {
  width: 60px;
  height: 42px;
  position: relative;
}
body.page-purchase #cart article.special-item img, body.page-purchase #cart-mobile #cart-cartItems-mobile article.special-item img {
  float: none;
  max-width: 60px;
  max-height: 42px;
  margin: auto;
}
body.page-purchase #cart article.special-item div, body.page-purchase #cart-mobile #cart-cartItems-mobile article.special-item div {
  display: table-cell;
  vertical-align: middle;
}
body.page-purchase #cart article.special-item div.description, body.page-purchase #cart-mobile #cart-cartItems-mobile article.special-item div.description {
  padding-left: 10px;
  max-width: 270px;
}
body.page-purchase #cart article.special-item div.description .residual, body.page-purchase #cart-mobile #cart-cartItems-mobile article.special-item div.description .residual {
  color: #ff0000;
}
body.page-purchase #cart article.special-item div.price, body.page-purchase #cart-mobile #cart-cartItems-mobile article.special-item div.price {
  width: 90px;
  text-align: right;
}
body.page-purchase #cart article.special-item div.price .price-bar, body.page-purchase #cart-mobile #cart-cartItems-mobile article.special-item div.price .price-bar {
  position: relative;
}
body.page-purchase #cart .cart-sum, body.page-purchase #cart-mobile .cart-sum {
  margin-top: 10px;
}
body.page-purchase #cart .cart-sum p, body.page-purchase #cart-mobile .cart-sum p {
  font-size: 15px;
  font-weight: bold;
}
body.page-purchase .voucher-form .formerror span {
  width: auto;
  display: inline;
}
body.page-purchase #cart #cart-cartItems, body.page-purchase #cart-mobile #cart-cartItems-mobile {
  overflow: auto;
}
body.page-purchase #cart #cart-cartItems .addMarginTop, body.page-purchase #cart-mobile #cart-cartItems-mobile .addMarginTop {
  margin-top: 5px;
}
body.page-purchase #cart #cart-cartItems .addLineHeight, body.page-purchase #cart-mobile #cart-cartItems-mobile .addLineHeight {
  line-height: 30px;
}
@media (min-width: 801px) {
  body.page-purchase #cart #cart-cartItems, body.page-purchase #cart-mobile #cart-cartItems-mobile {
    max-height: 450px;
  }
}
@media (max-width: 800px) {
  body.page-purchase #cart #cart-cartItems, body.page-purchase #cart-mobile #cart-cartItems-mobile {
    overflow: hidden;
  }
}
body.page-purchase #cart article.voucher img, body.page-purchase #cart-mobile article.voucher img {
  width: 60px;
  height: auto;
  margin: auto 0px;
}
body.page-purchase #cart article.voucher div, body.page-purchase #cart article.voucher span, body.page-purchase #cart-mobile article.voucher div, body.page-purchase #cart-mobile article.voucher span {
  line-height: 42px;
}
body.page-purchase #cart div.row.error, body.page-purchase #cart-mobile div.row.error {
  margin-bottom: 0;
}
body.page-purchase #cart .readonly-summary article {
  display: block;
}
@keyframes jumping-chevron {
  0% {
    color: #bebebd;
  }
  50% {
    color: #ebecee;
  }
  100% {
    color: #bebebd;
  }
}
body.page-purchase #cart .scroll-indicator {
  background-color: white;
  width: 32px;
  height: 22px;
  position: absolute;
  left: calc(50% - 15px);
  color: #bebebd;
}
body.page-purchase #cart .scroll-indicator.scroll-indicator--top {
  top: 30px;
}
body.page-purchase #cart .scroll-indicator.scroll-indicator--bottom {
  transform: rotate(180deg);
}
body.page-purchase #cart .scroll-indicator div.icon {
  text-align: center;
  animation: jumping-chevron 1.5s infinite ease-out;
}
body.page-purchase #cart .scroll-indicator div.icon:nth-child(2) {
  margin-top: -7px;
  animation-delay: 750ms;
}
body.page-purchase .cart-cartItems::-webkit-scrollbar {
  -webkit-appearance: none;
}
body.page-purchase .cart-cartItems::-webkit-scrollbar:vertical {
  width: 12px;
}
body.page-purchase .cart-cartItems::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
}
body.page-purchase .cart-cartItems::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}
@supports (-moz-osx-font-smoothing: auto) {
  body.page-purchase #cart #cart-cartItems {
    padding-right: 12px;
  }
}
body.page-purchase #continue-shopping {
  padding: 0px 10px 10px 0px;
  margin-right: 10px;
}
body.page-purchase #continue-shopping a {
  text-decoration: underline;
}
body.page-purchase #continue-shopping.hide-mobile {
  margin-right: 20px;
}
body.page-purchase .nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
body.page-purchase .nav:before, body.page-purchase .nav:after {
  content: " ";
  display: table;
}
body.page-purchase .nav:after {
  clear: both;
}
body.page-purchase .nav > li {
  position: relative;
  display: block;
}
body.page-purchase .nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}
body.page-purchase .nav > li > a:hover, body.page-purchase .nav > li > a:focus {
  text-decoration: none;
  background-color: #eeeeee;
}
body.page-purchase .nav > li.disabled > a {
  color: #777777;
}
body.page-purchase .nav > li.disabled > a:hover, body.page-purchase .nav > li.disabled > a:focus {
  color: #777777;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}
body.page-purchase .nav .open > a, body.page-purchase .nav .open > a:hover, body.page-purchase .nav .open > a:focus {
  background-color: #eeeeee;
  border-color: #337ab7;
}
body.page-purchase .nav .nav-divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
body.page-purchase .nav > li > a > img {
  max-width: none;
}
body.page-purchase .nav-tabs {
  border-bottom: 1px solid #ddd;
  margin: 0;
  background-color: #eeeeee;
}
body.page-purchase .nav-tabs > li {
  float: left;
  margin-bottom: -1px;
  text-align: center;
}
body.page-purchase .nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.428571429;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  background-color: #f3f4f7;
}
body.page-purchase .nav-tabs > li > a:hover {
  border-color: #e6e5ea #e6e5ea #ddd;
}
body.page-purchase .nav-tabs > li.active > a, body.page-purchase .nav-tabs > li.active > a:hover, body.page-purchase .nav-tabs > li.active > a:focus {
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
  opacity: 1;
}
body.page-purchase .nav-tabs > li.col-6 {
  width: 50%;
}
body.page-purchase .nav-tabs > li.col-4 {
  width: 33.33%;
}
body.page-purchase .nav-tabs > li.col-4 img {
  height: 14px;
  margin-top: 4px;
}
body.page-purchase .nav-tabs > li.col-4:first-of-type a {
  margin-left: -1px;
}
body.page-purchase .nav-tabs > li.col-4:last-of-type {
  width: 33.34%;
}
body.page-purchase .nav-tabs > li.col-4:last-of-type a {
  margin-right: -1px;
}
body.page-purchase .price-bar select {
  margin-right: 10px;
  height: 19.2px;
  min-width: 80px;
  font-size: 12px;
  line-height: 19.2px;
  padding-left: 0px;
  border: 1px solid #bebebd;
  border-radius: 0px;
}
body.page-purchase #insurance input[type=checkbox] + label {
  position: relative;
}
body.page-purchase #insurance input[type=checkbox] + label span, body.page-purchase #insurance input[type=radio] + label span {
  position: absolute;
  bottom: 3px;
  right: 0px;
}
body.page-purchase input[type=checkbox], body.page-purchase input[type=radio] {
  display: none;
}
body.page-purchase input[type=checkbox] + label span, body.page-purchase input[type=radio] + label span {
  display: inline-block;
  background: url("/img/purchase/other/box.png") no-repeat;
  background-size: cover;
  width: 19px;
  height: 21px;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}
body.page-purchase input[type=checkbox] + label span.left, body.page-purchase input[type=radio] + label span.left {
  float: left;
  margin-left: 0px;
  margin-right: 10px;
}
body.page-purchase input[type=checkbox] + label, body.page-purchase input[type=radio] + label {
  display: flex;
}
body.page-purchase input[type=checkbox] + label .label-col, body.page-purchase input[type=radio] + label .label-col {
  float: left;
}
body.page-purchase input[type=checkbox] + label .label-col.right, body.page-purchase input[type=radio] + label .label-col.right {
  margin-left: auto;
}
body.page-purchase input[type=checkbox].left + label span, body.page-purchase input[type=radio].left + label span {
  float: left;
  margin-left: 0px;
  margin-right: 10px;
}
body.page-purchase input[type=checkbox] + label, body.page-purchase input[type=radio].left + label, body.page-purchase input[type=radio] + label {
  margin-left: 0;
  line-height: 23px;
}
body.page-purchase input[type=checkbox]:checked + label span, body.page-purchase input[type=radio]:checked + label span {
  background: url("/img/purchase/other/checkbox.png") no-repeat;
  background-size: cover;
}
body.page-purchase input[type=checkbox].left:checked + label span, body.page-purchase input[type=radio].left:checked + label span {
  margin-right: 10px;
}
body.page-purchase form .formrow {
  margin-bottom: 10px;
  width: 100%;
  float: left;
}
body.page-purchase form .formrow .third {
  width: calc(33% - 5px);
  float: left;
}
body.page-purchase form .formrow .third:nth-child(3) {
  width: 34%;
}
body.page-purchase form .formrow .two-third {
  float: left;
  width: 66%;
}
body.page-purchase form .formrow.no-space {
  margin-bottom: 0;
}
body.page-purchase form.spaced {
  margin-bottom: 10px;
}
body.page-purchase form.no-space {
  margin-bottom: 0;
}
body.page-purchase form .suggestion {
  margin-bottom: 10px;
  color: #878988;
}
body.page-purchase form .suggestion span {
  cursor: pointer;
  color: #0000ff;
}
body.page-purchase form label {
  margin-bottom: 10px;
  display: block;
}
body.page-purchase form label.no-space {
  margin-bottom: 0;
}
body.page-purchase form .radio-button-select {
  width: 100%;
}
body.page-purchase form .radio-button-select input[type=radio] {
  display: none;
}
body.page-purchase form .radio-button-select input[type=radio] + label {
  margin: 0;
  display: block;
  width: 50%;
  float: left;
  text-decoration: none;
  background-color: #bebebd;
  color: #ffffff;
  height: 36px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}
body.page-purchase form .radio-button-select input[type=radio]:first-child + label {
  border-right: 3px solid #ffffff;
}
body.page-purchase form .radio-button-select input[type=radio]:nth-child(2) + label {
  border-left: 2px solid #ffffff;
}
body.page-purchase form .radio-button-select input[type=radio]:checked + label {
  background-color: #878988;
}
body.page-purchase #register-email-form {
  margin-bottom: 0;
}
body.page-purchase #register {
  padding: 0;
}
body.page-purchase #register .content {
  padding: 20px;
}
body.page-purchase #login-form, body.page-purchase #form-register-password {
  margin-bottom: 0;
}
body.page-purchase #register .formrow button.disabled, body.page-purchase #form-personalization .formrow button.disabled {
  background-color: #bebebd;
}
body.page-purchase .showPassword {
  height: 36px;
  width: 21px;
  position: absolute;
  cursor: pointer;
  right: 10px;
}
body.page-purchase .showPassword.hidden {
  background: url("/img/purchase/other/Eye_gray.svg") no-repeat right center;
  background-size: contain;
}
body.page-purchase .showPassword.show {
  background: url("/img/purchase/other/Eye.svg") no-repeat right center;
  background-size: contain;
}
body.page-purchase .formrow {
  position: relative;
}
body.page-purchase .formrow .overlay {
  font-size: 14px;
  line-height: 14px;
  color: #878988;
  left: 0;
  position: absolute;
  top: 10px;
  padding: 0 5px;
  margin: 0;
}
body.page-purchase .formrow .overlay.on {
  /* Safari */
  -webkit-transition: left 0.2s ease-out 0s, top 0.2s ease-out 0s, line-height 0.2s ease-out 0s, font-size 0.2s ease-out 0s, background-color 0.2s ease-out 0s;
  transition: left 0.2s ease-out 0s, top 0.2s ease-out 0s, line-height 0.2s ease-out 0s, font-size 0.2s ease-out 0s, background-color 0.2s ease-out 0s;
  background: #ffffff;
  font-size: 9px;
  line-height: 9px;
  top: -4px;
  left: 8px;
}
body.page-purchase .formrow select {
  border: 1px solid #bebebd;
  background: #ffffff;
  border-radius: 0px;
}
body.page-purchase .formrow input {
  padding: 5px;
  box-shadow: none;
}
body.page-purchase .formrow input, body.page-purchase .formrow select {
  position: relative;
  float: left;
  width: 100%;
  height: 36px;
  border: 1px solid #bebebd;
  font-size: 13px;
  color: #000000;
}
body.page-purchase .formrow input.ng-valid:not(.ng-empty):not(.ng-untouched), body.page-purchase .formrow select.ng-valid:not(.ng-empty):not(.ng-untouched) {
  background: url("/img/purchase/other/check.png") no-repeat right 10px center;
  background-size: 12px 13px;
}
body.page-purchase .formrow input.ng-valid:not(.ng-empty):not(.ng-untouched).no-background, body.page-purchase .formrow select.ng-valid:not(.ng-empty):not(.ng-untouched).no-background {
  background-image: none;
}
body.page-purchase .formrow input::-webkit-input-placeholder, body.page-purchase .formrow select::-webkit-input-placeholder {
  color: #878988;
}
body.page-purchase .formrow input:-moz-placeholder, body.page-purchase .formrow select:-moz-placeholder { /* Firefox 18- */
  color: #878988;
}
body.page-purchase .formrow input::-moz-placeholder, body.page-purchase .formrow select::-moz-placeholder { /* Firefox 19+ */
  color: #878988;
}
body.page-purchase .formrow input:-ms-input-placeholder, body.page-purchase .formrow select:-ms-input-placeholder {
  color: #878988;
}
body.page-purchase .formrow input.half, body.page-purchase .formrow select.half {
  width: 50%;
}
body.page-purchase .formrow input.half:nth-child(2), body.page-purchase .formrow select.half:nth-child(2) {
  width: calc(50% - 5px);
}
body.page-purchase .formrow input.small, body.page-purchase .formrow select.small {
  width: 34%;
}
body.page-purchase .formrow input.small:nth-child(2), body.page-purchase .formrow select.small:nth-child(2) {
  width: calc(34% - 5px);
}
body.page-purchase .formrow input.big, body.page-purchase .formrow select.big {
  width: 66%;
}
body.page-purchase .formrow input.big:nth-child(2), body.page-purchase .formrow select.big:nth-child(2) {
  width: calc(66% - 5px);
}
body.page-purchase .formrow input:focus, body.page-purchase .formrow select:focus {
  border: 1px solid #00964f;
}
body.page-purchase .formrow input:nth-child(2), body.page-purchase .formrow select:nth-child(2) {
  margin-left: 5px;
}
body.page-purchase .formrow input:nth-child(3), body.page-purchase .formrow select:nth-child(3) {
  margin-left: 5px;
}
body.page-purchase .formrow button {
  width: 100%;
  background-color: #00964f;
  color: #ffffff;
  float: left;
  display: block;
  text-decoration: none;
  height: 36px;
  line-height: 36px;
  text-align: center;
  padding: 0;
  border-radius: 0px;
  border: none;
  margin: 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
}
body.page-purchase form.submit input:invalid, body.page-purchase form.submit select:invalid {
  border-color: #ff0000;
}
body.page-purchase form.submit select:invalid {
  border: 1px solid #ff0000;
}
body.page-purchase .formerror, body.page-purchase .ngFormError {
  float: left;
  display: block;
  width: 100%;
}
body.page-purchase .formerror span, body.page-purchase .ngFormError span {
  margin-bottom: 10px;
  width: 100%;
  display: block;
  color: #ff0000;
  font-size: 12px;
}
body.page-purchase .formerror span.no-space, body.page-purchase .ngFormError span.no-space {
  margin-bottom: 0;
}
body.page-purchase .formwarning {
  border: 1px solid #dc8700 !important;
}
body.page-purchase .address {
  display: flex;
}
body.page-purchase .address .img-wrapper {
  width: 20px;
  position: relative;
}
body.page-purchase .address .img-wrapper img {
  width: 20px;
  margin: auto;
}
body.page-purchase .address .text {
  width: auto;
  overflow: hidden;
}
body.page-purchase .address .text span {
  display: block;
  width: 100%;
}
body.page-purchase .address .text span.inline {
  display: inline;
}
@media (max-width: 800px) {
  body.page-purchase .formRow.m-s {
    margin-right: 5px;
  }
  body.page-purchase .formRow.m-1-3 {
    width: 33.3%;
  }
  body.page-purchase .formRow.m-1-3.m-s {
    width: calc(33.3% - 5px);
  }
  body.page-purchase .formRow.m-2-3 {
    width: 66.6%;
  }
  body.page-purchase .formRow.m-2-3.m-s {
    width: calc(66.6% - 5px);
  }
  body.page-purchase .formRow.m-1-4 {
    width: calc(16.6666666667% - 5px);
  }
  body.page-purchase .formRow.m-1-4.d-end {
    width: 16.6666666667%;
  }
  body.page-purchase .formRow.m-1-2 {
    width: 50%;
  }
  body.page-purchase .formRow.m-1-2.m-s {
    width: calc(50% - 5px);
  }
  body.page-purchase .formRow.m-3-3 {
    width: calc(33.3333333333% - 5px);
  }
  body.page-purchase .formRow.m-3-3.m-end {
    width: 33.3333333333%;
  }
}
@media (min-width: 800px) {
  body.page-purchase .formRow.d-s {
    margin-right: 5px;
  }
  body.page-purchase .formRow.d-1-3 {
    width: calc(16.6666666667% - 5px);
  }
  body.page-purchase .formRow.d-1-3.d-end {
    width: 16.6666666667%;
  }
  body.page-purchase .formRow.d-1-2 {
    width: 50%;
  }
  body.page-purchase .formRow.d-1-2.d-s {
    width: calc(50% - 5px);
  }
  body.page-purchase .formRow.d-1-2.d-end {
    width: calc(50% - 5px);
    margin-right: calc(50% + 5px);
  }
  body.page-purchase .formRow.d-no-space {
    margin-bottom: 0;
  }
}
body.page-purchase #register-email-form .success {
  cursor: pointer;
}
body.page-purchase .debit-check {
  margin-top: 20px;
}
body.page-purchase .debit-check input {
  float: none !important;
}
body.page-purchase .debit-check label {
  display: block !important;
  width: 100%;
  margin-top: 20px;
}
body.page-purchase .debit-check label .label-col {
  width: 100%;
  word-break: break-word;
}
body.page-purchase .debit-check label span {
  float: left !important;
  margin-left: 0 !important;
}
body.page-purchase .debit-check label .debit-accept-text {
  padding-left: 30px;
  line-height: 2em;
}
body.page-purchase .modal {
  display: none;
  position: fixed;
  width: 100%;
  max-width: 500px;
  padding: 25px;
  background-color: #ffffff;
  left: 0 !important;
  margin-left: 0 !important;
  z-index: 999;
}
body.page-purchase .modal .close-modal {
  width: 15px;
  height: 15px;
  display: block;
  top: 25px;
  right: 25px;
  position: absolute;
  background-image: url("/img/purchase/other/icon_cross.png");
  background-size: cover;
}
body.page-purchase .modal .content {
  margin: 10px auto;
  max-height: 250px;
  overflow: auto;
}
body.page-purchase .modal img {
  width: 100%;
  height: auto;
}
body.page-purchase .modal p {
  margin-bottom: 10px;
}
body.page-purchase .modal p:last-child {
  margin-bottom: 0px;
}
body.page-purchase .modal-payment {
  max-width: 1000px;
}
body.page-purchase .modal {
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.75);
}
body.page-purchase #lean_overlay {
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: #000000;
  display: none;
}
@media (min-width: 500px) {
  body.page-purchase .modal {
    margin-left: -250px !important;
    left: 50% !important;
  }
  body.page-purchase .modal-payment {
    margin-left: -500px !important;
    left: 50% !important;
    height: 500px;
    max-height: 80%;
  }
}
@media (max-width: 500px) {
  body.page-purchase .modal {
    top: 60px !important;
  }
  body.page-purchase .modal-payment {
    height: 80%;
    max-height: 500px;
  }
}
@media (min-width: 800px) {
  body.page-purchase .left-column {
    width: 60%;
    float: left;
  }
  body.page-purchase .right-column {
    float: left;
    width: 40%;
  }
  body.page-purchase .hide-desktop {
    display: none !important;
  }
}
@media (max-width: 800px) {
  body.page-purchase .hide-mobile {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  body.page-purchase .right-column .fixed {
    width: 480px;
  }
}
body.page-purchase .modal.product {
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  padding: 0;
  max-width: 100%;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body.page-purchase .modal.product .inModal {
  position: relative;
  top: 25px;
  max-width: 680px;
  margin: 0 auto;
  padding: 25px 5px 55px 25px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.75);
}
body.page-purchase .modal.product .content {
  max-height: 400px;
  padding-right: 20px;
}
body.page-purchase .product .name {
  margin-bottom: 20px;
}
body.page-purchase .product .images {
  height: 270px;
  width: 365px;
  float: left;
  margin-bottom: 5px;
  margin-right: 70px;
}
body.page-purchase .product .images .productImageContainer.big {
  width: 250px;
  height: 270px;
  float: left;
}
body.page-purchase .product .images .alternatives {
  display: inline-block;
  width: 80px;
  height: 100%;
  float: right;
}
body.page-purchase .product .images .alternatives .productImageContainer {
  cursor: pointer;
  height: 86px;
  margin-bottom: 5px;
}
body.page-purchase .product .productsizes {
  float: left;
}
body.page-purchase .product .productsizes .productsizeitem {
  margin-bottom: 5px;
}
body.page-purchase .product .label {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
}
body.page-purchase .product .addAndRemove {
  display: inline-block;
  height: 100%;
}
body.page-purchase .product .addAndRemove span {
  margin: 0 2px;
  width: 15px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
body.page-purchase .product .addAndRemove button {
  background-image: url("/img/purchase/other/Plus_Icon.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 25px;
  height: 25px;
}
body.page-purchase .product .addAndRemove button:focus {
  outline: none;
}
body.page-purchase .product .addAndRemove button.removeProduct {
  background-image: url("/img/purchase/other/Minus_Icon.png");
}
body.page-purchase .product .description {
  max-width: 560px;
}
body.page-purchase .product .addToCart {
  width: 167px;
  height: 36px;
  position: absolute;
  right: 25px;
  bottom: 25px;
}
@media (max-width: 800px) {
  body.page-purchase .productImageContainer.big {
    margin: 0 auto;
    width: 250px;
    height: 270px;
    float: none;
    /** Carousel Controls **/
  }
  body.page-purchase .productImageContainer.big .images {
    width: 100%;
    height: 270px;
    float: left;
    margin-bottom: 5px;
    margin-right: 0;
  }
  body.page-purchase .productImageContainer.big img {
    width: 250px;
    height: auto;
  }
  body.page-purchase .productImageContainer.big .controlContainer {
    position: relative;
    height: 100%;
  }
  body.page-purchase .productImageContainer.big .prev, body.page-purchase .productImageContainer.big .next {
    z-index: 200;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    text-align: center;
    background: #4E443C;
    color: #FFF;
    text-decoration: none;
    text-shadow: 0 0 1px #000;
    font: 24px/27px Arial, sans-serif;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    -webkit-box-shadow: 0 0 4px #F0EFE7;
    -moz-box-shadow: 0 0 4px #F0EFE7;
    box-shadow: 0 0 4px #F0EFE7;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
         supported by Chrome and Opera */
  }
  body.page-purchase .productImageContainer.big .prev {
    left: -35px;
  }
  body.page-purchase .productImageContainer.big .next {
    right: -35px;
  }
  body.page-purchase .modal.product {
    padding-bottom: 60px;
  }
  body.page-purchase .product .label {
    width: 245px;
  }
  body.page-purchase .product .productsizes {
    float: none;
  }
  body.page-purchase .product .description {
    width: 100%;
  }
  body.page-purchase .product .images {
    width: 100%;
  }
  body.page-purchase .product .images .productImageContainer.big {
    float: none;
    margin: 0 auto 25px auto;
  }
  body.page-purchase .product .inModal {
    padding-bottom: 25px;
  }
  body.page-purchase .product .addToCart {
    width: auto;
    height: auto;
    position: relative;
    right: auto;
    bottom: auto;
    margin-right: 20px;
  }
  body.page-purchase .product .addToCart .button {
    float: none;
    height: auto;
  }
}
body.page-purchase section[id=elefunds-module], body.page-purchase section[id=donation] {
  overflow: visible !important;
}
body.page-purchase section.elefunds {
  padding: 0 !important;
}
body.page-purchase section.elefunds .section-middle .receiver-tooltip {
  width: 100% !important;
  margin-left: -50% !important;
}
body.page-purchase #elefunds-module {
  line-height: 8px !important;
}
body.page-purchase #elefunds-module .section-bottom {
  line-height: 15px !important;
  height: 60px !important;
}
body.page-purchase #elefunds-module .section-top .region-activate-module .region-checklabel {
  padding: 0 !important;
}
@media (max-width: 800px) {
  body.page-purchase section.elefunds {
    overflow-x: hidden !important;
  }
  body.page-purchase section.elefunds .receiver-tooltip {
    left: 50% !important;
    top: -58px !important;
  }
}
body.page-purchase #amazonpay label > .img-wrapper {
  margin-right: 30px;
  width: 190px;
  position: relative;
}
body.page-purchase #amazonpay label > .img-wrapper img {
  max-height: 42px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
body.page-purchase #amazonpay #addressBookWidgetDiv {
  min-width: 300px;
  width: 100%;
  max-width: 900px;
  min-height: 228px;
  height: 240px;
  max-height: 400px;
}
body.page-purchase #amazonpay #addressBookWidgetDiv iframe {
  z-index: 0 !important;
}
body.page-purchase #amazonpay #walletWidgetDiv {
  min-width: 300px;
  width: 100%;
  max-width: 900px;
  min-height: 228px;
  height: 240px;
  max-height: 400px;
}
body.page-purchase #amazonpay #walletWidgetDiv iframe {
  z-index: 0 !important;
}
body.page-purchase #amazonpay #readOnlyAddressBookWidgetDiv {
  min-width: 266px;
  width: 100%;
  max-width: 900px;
  min-height: 145px;
  height: 165px;
  max-height: 180px;
}
body.page-purchase #amazonpay #readOnlyAddressBookWidgetDiv iframe {
  z-index: 0 !important;
}
body.page-purchase #amazonpay #readOnlyWalletWidgetDiv {
  min-width: 266px;
  width: 100%;
  max-width: 900px;
  min-height: 145px;
  height: 165px;
  max-height: 180px;
}
body.page-purchase #amazonpay #readOnlyWalletWidgetDiv iframe {
  z-index: 0 !important;
}
@media (max-width: 800px) {
  body.page-purchase #amazonpay #addressBookWidgetDiv {
    min-width: 100px;
  }
  body.page-purchase #amazonpay #walletWidgetDiv {
    min-width: 100px;
  }
  body.page-purchase #amazonpay #readOnlyAddressBookWidgetDiv {
    min-width: 100px;
  }
  body.page-purchase #amazonpay #readOnlyWalletWidgetDiv {
    min-width: 100px;
  }
}
body.page-purchase #AmazonPayButton img {
  width: 200px;
  height: 45px;
  float: right;
}
body.page-purchase .amazonpay-content {
  display: flex;
}
body.page-purchase .amazonpay-content .amazon-image {
  width: 220px;
}
body.page-purchase .amazonpay-content .amazon-image img {
  width: 200px;
}
body.page-purchase .amazonpay-error {
  border: 1px #ff0000 solid;
  min-height: 40px;
  padding: 10px;
}
body.page-purchase .amazonpay-error p {
  color: #ff0000;
  font-weight: 600;
  text-align: center;
}
body.page-purchase #insuranceListing {
  padding-top: 20px;
}
body.page-purchase #insuranceListing .insuranceTotal {
  margin-top: 5px;
}
body.page-purchase #insuranceListing .insurancePosition, body.page-purchase #insuranceListing .insuranceTotal {
  margin-bottom: 20px;
}
body.page-purchase #insuranceListing .insurancePosition .insuranceText, body.page-purchase #insuranceListing .insuranceTotal .insuranceText {
  float: left;
  margin-left: 90px;
}
@media (max-width: 800px) {
  body.page-purchase #insuranceListing .insurancePosition .insuranceText, body.page-purchase #insuranceListing .insuranceTotal .insuranceText {
    margin-left: 60px;
    max-width: 200px;
  }
}
body.page-purchase #insuranceListing .insurancePosition .insuranceTextTotal, body.page-purchase #insuranceListing .insuranceTotal .insuranceTextTotal {
  float: left;
  margin-left: 90px;
}
@media (max-width: 800px) {
  body.page-purchase #insuranceListing .insurancePosition .insuranceTextTotal, body.page-purchase #insuranceListing .insuranceTotal .insuranceTextTotal {
    margin-left: 60px;
    max-width: 200px;
  }
}
body.page-purchase #insuranceListing .insurancePosition .insurancePrice, body.page-purchase #insuranceListing .insuranceTotal .insurancePrice {
  float: right;
  text-align: right;
}
body.page-purchase .chevron {
  position: relative;
  left: 5px;
  height: 15px;
}
body.page-purchase .chevron:before,
body.page-purchase .chevron:after {
  position: absolute;
  content: "";
  border: 10px solid transparent;
}
body.page-purchase .chevron-down:before {
  top: 0;
  border-top-color: #bebebd;
}
body.page-purchase .chevron-down:after {
  top: -4px;
  border-top-color: #fff;
}
body.page-purchase .chevron-down {
  top: 3px;
}
body.page-purchase .chevron-up:before {
  bottom: 0;
  border-bottom-color: #bebebd;
}
body.page-purchase .chevron-up:after {
  bottom: -4px;
  border-bottom-color: #fff;
}
body.page-purchase .chevron-up {
  bottom: 3px;
}
body.page-purchase .pricecategory-border {
  border-left: 3px solid;
  padding-left: 5px;
}
body.page-purchase .pk-1 {
  border-color: #FDC54A;
}
body.page-purchase .pk-2 {
  border-color: #FF534E;
}
body.page-purchase .pk-3 {
  border-color: #009CCA;
}
body.page-purchase .pk-4 {
  border-color: #FF883E;
}
body.page-purchase .pk-5 {
  border-color: #40B394;
}
body.page-purchase .pk-6 {
  border-color: #8976B6;
}
body.page-purchase .pk-7 {
  border-color: #AA745C;
}
body.page-purchase .pk-8 {
  border-color: #69737C;
}
body.page-purchase .pk-9 {
  border-color: #4668B0;
}
body.page-purchase .pk-10 {
  border-color: #BE6AA8;
}
body.page-purchase .pk-11 {
  border-color: #42BAB9;
}
body.page-purchase .pk-12 {
  border-color: #867271;
}
body.page-purchase .pk-13 {
  border-color: #FEECBE;
}
body.page-purchase .pk-14 {
  border-color: #94D3DC;
}
body.page-purchase .pk-15 {
  border-color: #CCCDE9;
}
body.page-purchase .pk-16 {
  border-color: #F3F2F7;
}
body.page-purchase .pk-17 {
  border-color: #B0A2A2;
}
body.page-purchase .pk-18 {
  border-color: #D59BC5;
}
body.page-purchase .pk-19 {
  border-color: #B0B6C6;
}
body.page-purchase .pk-20 {
  border-color: #E4E4E4;
}
body.page-purchase #cookie-banner {
  z-index: 1050;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 10px 0 20px;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  body.page-purchase #cookie-banner {
    padding: 20px;
  }
}
@media (max-width: 300px) {
  body.page-purchase #cookie-banner {
    width: auto;
  }
}
body.page-purchase #cookie-banner .container {
  max-width: 1200px;
  margin: 0 auto;
}
body.page-purchase #cookie-banner .cookie-text {
  display: block;
  max-height: 25vh;
  overflow-y: auto;
}
@media (max-width: 420px) {
  body.page-purchase #cookie-banner .cookie-text {
    padding-bottom: 30px;
  }
}
body.page-purchase #cookie-banner h3 {
  font-size: 18px;
  margin: 10px 0;
}
body.page-purchase #cookie-banner p {
  padding: 10px 0;
}
@media (max-width: 768px) {
  body.page-purchase #cookie-banner p {
    padding: 5px 0 30px;
  }
}
body.page-purchase #cookie-banner p#decline_all {
  padding-top: 10px;
  color: #888;
}
body.page-purchase #cookie-banner p#decline_all:hover {
  cursor: pointer;
}
body.page-purchase #cookie-banner a {
  color: #333;
}
@media (max-width: 768px) {
  body.page-purchase #cookie-banner .cookie-buttons::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: -30px;
    height: 30px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(255, 255, 255, 0.5) 30%, #fff 100%);
    pointer-events: none;
  }
}
body.page-purchase #cookie-banner button {
  height: 36px;
  border: none;
  float: right;
  margin: 0 0 0 10px;
  padding: 0 35px;
  font-weight: bold;
  font-size: 13px;
}
@media (max-width: 420px) {
  body.page-purchase #cookie-banner button {
    width: 100%;
    margin: 5px 0;
    float: none;
  }
}
body.page-purchase #cookie-banner button#accept_all {
  background-color: #00964f;
  color: #ffffff;
}
body.page-purchase #cookie-banner p.small {
  font-size: 85%;
  margin: 5px 0 0;
  color: #888;
}
body.page-purchase #cookie-banner .cookie-buttons.row {
  padding: 25px 0;
}
body.page-purchase #cookie_settings_modal {
  padding: 25px 25px 0;
  display: none;
  top: 50px;
  overflow: auto;
  max-height: calc(100% - 75px);
  min-height: min(100vh, 200px);
}
@media (min-width: 421px) {
  body.page-purchase #cookie_settings_modal {
    max-height: calc(100% - 300px);
  }
}
body.page-purchase #cookie_settings_modal .cookie-banner__link {
  background: rgba(255, 255, 255, 0.5);
  padding: 5px 30px;
  color: #878988;
}
body.page-purchase #cookie_settings_modal .cookie-banner__link:hover {
  cursor: pointer;
}
body.page-purchase #cookie_settings_modal [type=checkbox] + label::before {
  height: 1.1875rem;
  width: 1.1875rem;
}
body.page-purchase #cookie_settings_modal [type=checkbox]:checked + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M5 17.032c0-.58.484-1 1-1 .387 0 .484.065.71.29L12 21.582 25.258 8.29a.925.925 0 01.71-.291C26.548 8 27 8.516 27 9c0 .258-.097.484-.323.71l-13.967 14c-.194.193-.452.29-.71.29a.925.925 0 01-.71-.29l-6-5.968a1 1 0 01-.29-.71z' fill='%23000'/%3E%3C/svg%3E");
  background-position: 0 0;
  background-repeat: no-repeat;
  display: block;
  height: 1.25rem;
  position: relative;
  width: 1.25rem;
  left: 0.0625rem;
  position: absolute;
  top: 0;
}
body.page-purchase #cookie_settings_modal .form__field--input-option:checked + label::after {
  content: "";
}
body.page-purchase #cookie_settings_modal .form__field--input-option:checked + label {
  font-weight: bolder;
}
body.page-purchase #cookie_settings_modal .cookie-banner__category {
  padding: 0 0 10px 0;
}
body.page-purchase #cookie_settings_modal .cookie-banner__category p {
  margin: 0 10px;
  padding: 2px 20px;
}
body.page-purchase #cookie_settings_modal .cookie-banner__category label {
  font-size: 1rem;
  line-height: 1.33em;
  margin-bottom: 0.5rem;
}
body.page-purchase #cookie_settings_modal .form__field--input-option {
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}
body.page-purchase #cookie_settings_modal .form__field--input-option + label::before {
  background: #fff;
  border: 0.0625rem solid #878c96;
  -webkit-box-shadow: 0.0625rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.15) inset;
  box-shadow: inset 0.0625rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.15);
  content: "";
  left: 0;
  position: absolute;
  top: 0;
}
body.page-purchase #cookie_settings_modal .form__field--input-option + label {
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.5em;
  padding-left: 1.75rem;
  padding-right: 0.625rem;
  position: relative;
}
body.page-purchase #cookie_settings_modal .form__field--input-option#essential + label {
  color: #878c96;
}
body.page-purchase #cookie_settings_modal .cookie-buttons button {
  width: 100%;
  background-color: #00964f;
  color: #ffffff;
  float: left;
  display: block;
  text-decoration: none;
  height: 36px;
  line-height: 36px;
  text-align: center;
  padding: 0;
  border-radius: 0px;
  border: none;
  margin: 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
}
body.page-purchase #cookie_settings_modal .cookie-buttons button#accept_selected, body.page-purchase #cookie_settings_modal .cookie-buttons button.cookie-banner__button--back {
  background-color: #bebebd;
}
body.page-purchase #cookie_settings_modal .cookie-buttons button:hover {
  padding: 0 35px;
}
body.page-purchase #cookie_settings_modal .cookie-buttons p {
  text-decoration: underline;
}
body.page-purchase #cookie_settings_modal .modal-content.details p {
  padding: 0;
}
body.page-purchase .cookie-banner__categories, body.page-purchase .cookie-banner__table {
  max-height: calc(100vh - 500px);
  overflow-y: scroll;
}
body.page-purchase .cookie-banner__table {
  border-collapse: inherit;
  border-spacing: 0 0.5rem;
  margin-bottom: 1.25rem;
}
body.page-purchase .cookie-banner__table tr {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1.25rem;
}
body.page-purchase .cookie-banner__table tr td {
  vertical-align: baseline;
}
@media (max-width: 767px) {
  body.page-purchase table.cookie-banner__table thead {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  body.page-purchase .cookie-banner__table-headline {
    display: none;
  }
  body.page-purchase .cookie-banner__table tr {
    display: table-row;
    margin-bottom: 0;
  }
}
body.page-purchase .button-group {
  display: inline-flex;
  width: 100%;
}
body.page-purchase a.button {
  display: block;
  width: 100%;
  background-color: #00964f;
  color: #ffffff;
  float: left;
  height: 36px;
  line-height: 36px;
  text-align: center;
  padding: 0;
  margin: 5px 0;
  transition: background-color 200ms;
}
body.page-purchase a.button.inactive, body.page-purchase a.button.decline {
  background-color: #bebebd;
}
body.page-purchase a.button.nomargin {
  margin: 0;
}
body.page-purchase a.button.inline + .inline {
  margin-left: 10px;
}
body.page-purchase .clearfix {
  clear: both;
}
body.page-purchase .pull-right {
  float: right;
}
body.page-purchase .pull-left {
  float: left;
}
body.page-purchase .spaced {
  margin: 10px 0;
}
body.page-purchase .spaced-top {
  margin-top: 10px;
}
body.page-purchase .spaced-bottom {
  margin-bottom: 10px;
}
body.page-purchase .success {
  color: #00964f;
}
body.page-purchase .error {
  color: #ff0000;
}
body.page-purchase hr {
  float: left;
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
  background-color: #bebebd;
  border: 0;
}
body.page-purchase hr.both {
  margin-top: 10px;
}
body.page-purchase .row {
  width: 100%;
  float: left;
  margin: 10px 0;
}
body.page-purchase #insurance label > .img-wrapper {
  margin-right: 30px;
  width: 60px;
  position: relative;
}
body.page-purchase #insurance label > .img-wrapper img {
  max-width: 60px;
  max-height: 42px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
body.page-purchase #amazonpay label > .img-wrapper {
  margin-right: 30px;
  width: 190px;
  position: relative;
}
body.page-purchase #amazonpay label > .img-wrapper img {
  max-height: 42px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
body.page-purchase section {
  background-color: #ffffff;
  padding: 10px;
  margin: 10px;
  overflow: hidden;
}
body.page-purchase section .text {
  overflow: hidden;
}
body.page-purchase section article {
  width: 100%;
  margin-bottom: 10px;
}
body.page-purchase section article .modalTrigger {
  float: right;
}
body.page-purchase section article:last-of-type, body.page-purchase section article.active {
  margin-bottom: 0px;
}
body.page-purchase section article img {
  float: left;
  max-width: 60px;
  margin-right: 10px;
}
body.page-purchase section .residualtext {
  margin-top: 5px;
  color: #00964e;
}
body.page-purchase section .residualtext.more {
  color: #FF0000;
}
body.page-purchase section#ticketformat article img {
  max-width: 112px;
}
body.page-purchase #crosssell section.giftwrapper article {
  cursor: auto;
  margin-bottom: 10px;
}
body.page-purchase #crosssell section.giftwrapper article .text {
  text-align: center;
}
body.page-purchase #crosssell section.giftwrapper article:last-of-type, body.page-purchase #crosssell section.giftwrapper article.active {
  margin-bottom: 0;
}
body.page-purchase #crosssell section.giftwrapper article.moreGiftwrapper .productImageContainer {
  margin-left: 0;
}
body.page-purchase #crosssell section.giftwrapper .productImageContainer {
  text-align: center;
  margin-left: 29px;
}
body.page-purchase #crosssell section.giftwrapper .addAndRemove {
  float: right;
  width: 25%;
  min-width: 20px;
  text-align: center;
}
body.page-purchase #crosssell section.giftwrapper .addAndRemove img {
  width: 25px;
  margin: 5px;
}
body.page-purchase #crosssell section.giftwrapper .addAndRemove div {
  margin: 0;
}
body.page-purchase #crosssell section article {
  float: left;
  max-width: 33.33%;
  margin-left: 0;
  cursor: pointer;
}
body.page-purchase #crosssell section article div {
  position: relative;
}
body.page-purchase #crosssell section article.clearfix-desktop {
  clear: both;
}
body.page-purchase #crosssell section article.clearfix-mobile {
  clear: none;
}
body.page-purchase #crosssell section article .text {
  text-align: left;
  margin-top: 5px;
}
body.page-purchase #crosssell section article input[type=checkbox] + span {
  display: inline-block;
  background-image: url("/img/purchase/other/box.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 19px;
  height: 100%;
  position: absolute;
  margin: auto 0 auto 5px;
  cursor: pointer;
}
body.page-purchase #crosssell section article input[type=checkbox]:checked + span {
  background-image: url("/img/purchase/other/checkbox.png");
}
body.page-purchase #crosssell section article .productImageContainer {
  display: inline-block;
  width: 75%;
  max-height: 100px;
  height: 100%;
  text-align: left;
}
body.page-purchase #crosssell section article img {
  max-height: 100px;
  max-width: 100%;
  float: none;
  margin-right: 0px;
}
body.page-purchase #crosssell section article div.text-center {
  text-align: center;
}
body.page-purchase #crosssell section article .modalTrigger {
  float: none;
  text-align: center;
}
body.page-purchase #crosssell section article .modalTrigger .productImageContainer {
  width: inherit;
}
body.page-purchase section#payment #register-form-cc {
  padding-top: 20px;
}
body.page-purchase div.change-selection {
  text-align: center;
}
body.page-purchase div.change-selection a {
  line-height: 1em;
  text-decoration: none;
  color: #878988;
  font-weight: normal;
  padding-right: 15px;
  background: url("/img/purchase/other/arrow_active.png") right no-repeat;
  background-size: 10px;
}
@media (max-width: 800px) {
  body.page-purchase #crosssell section article {
    max-width: 49%;
  }
  body.page-purchase #crosssell section article.clearfix-desktop {
    clear: none;
  }
  body.page-purchase #crosssell section article.clearfix-mobile {
    clear: both;
  }
}
body.page-purchase .checkout-notice, body.page-purchase #headover {
  display: flex;
}
body.page-purchase .checkout-notice .content, body.page-purchase #headover .content {
  display: flex;
  align-items: center;
}
body.page-purchase .checkout-notice, body.page-purchase .reservation-box {
  position: relative;
  background-color: #ffffff;
  margin: 10px;
  padding: 10px;
  border: 1px solid #00964f;
}
body.page-purchase .reservation-box .reservation-box-content {
  margin-top: 10px;
}
body.page-purchase .reservation-box .reservation-feedback {
  color: #ff0000;
  display: none;
}
body.page-purchase .reservation-box .reservation-feedback-error {
  display: none;
}
body.page-purchase .checkout-notice .content {
  margin-left: 30px;
}
body.page-purchase .checkout-notice .content strong {
  display: block;
}
body.page-purchase .error-border {
  border: 1px solid #ff0000;
}
body.page-purchase #page-links {
  text-align: center;
  margin-bottom: 20px;
}
body.page-purchase #page-links a {
  display: inline-block;
  margin: 0 5px;
}
body.page-purchase #error-container {
  display: none;
}
body.page-purchase #error-container span {
  display: block;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ff0000;
  background-color: #ffffff;
}
body.page-purchase .checkout-notice .checkmark, body.page-purchase #headover .checkmark {
  position: absolute;
  left: 10px;
  top: 50%;
}
body.page-purchase .checkout-notice .checkmark img, body.page-purchase #headover .checkmark img {
  position: relative;
  top: -11px;
  width: 20px;
  height: 22px;
}
body.page-purchase #headover {
  position: relative;
  min-height: 50px;
  border: 1px solid #00964f;
  padding: 10px;
}
body.page-purchase #headover .arrow-right {
  position: absolute;
  right: 10px;
  top: 50%;
}
body.page-purchase #headover .arrow-right img {
  position: relative;
  height: 28px;
  width: 40px;
  top: -14px;
}
body.page-purchase #headover p {
  margin-left: 30px;
  margin-right: 50px;
}
body.page-purchase #payment form {
  margin-bottom: 10px;
}
body.page-purchase #payment-information .label {
  color: #878988;
  text-align: center;
  margin-bottom: 10px;
}
body.page-purchase #payment-information .content {
  text-align: center;
}
body.page-purchase #payment-information .content .payment-option {
  margin: 0 10px 10px;
  display: inline-block;
}
body.page-purchase #payment-information .content .payment-option img {
  height: 30px;
  width: auto;
}
body.page-purchase #wrapper {
  display: none;
  max-width: 1200px;
  margin: auto;
}
body.page-purchase #spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -92px;
  margin-top: -92px;
}
body.page-purchase #desktop-spacer {
  width: 100%;
  float: left;
}
body.page-purchase #register .logged-in img {
  width: 20px;
  margin-top: 3px;
  margin-right: 10px;
  float: left;
}
body.page-purchase header {
  background-color: #2d5fab;
  color: #ffffff;
  width: 100%;
  z-index: 1;
}
body.page-purchase header .header-wrapper {
  max-width: 1200px;
  min-height: 30px;
  margin: auto;
  padding: 10px 0;
  position: relative;
}
body.page-purchase header a.brand {
  margin-left: 10px;
  display: inline-block;
}
body.page-purchase header a.brand img {
  max-height: 60px;
  max-width: 200px;
}
body.page-purchase header .invert-header-mode {
  filter: invert(100%);
}
body.page-purchase header .counter {
  background-image: url("/img/purchase/other/clock.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: 30px;
  padding-left: 30px;
  display: inline-block;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
}
body.page-purchase header .counter .middler {
  vertical-align: middle;
  display: inline-block;
  height: 100%;
}
body.page-purchase .progressbarContainer {
  margin-bottom: 70px;
  position: relative;
  z-index: 2;
}
body.page-purchase div#auto-progressbar, body.page-purchase div.progressbar {
  padding: 10px;
  height: 70px;
  position: fixed;
  width: 60%;
  max-width: 720px;
}
body.page-purchase div#auto-progressbar img, body.page-purchase div.progressbar img {
  height: 50px;
}
body.page-purchase div#auto-progressbar .hide-desktop img, body.page-purchase div.progressbar .hide-desktop img {
  height: 40px;
}
body.page-purchase div#auto-progressbar table, body.page-purchase div#auto-progressbar th, body.page-purchase div#auto-progressbar td, body.page-purchase div.progressbar table, body.page-purchase div.progressbar th, body.page-purchase div.progressbar td {
  border: 0;
  padding: 0;
}
body.page-purchase div#auto-progressbar table, body.page-purchase div.progressbar table {
  border-spacing: 0;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
@media (max-width: 800px) {
  body.page-purchase div#auto-progressbar {
    position: relative;
    z-index: 2;
    width: auto;
    max-width: none;
  }
}
body.page-purchase section.inactive-step {
  opacity: 0.6;
  padding: 5px;
}
body.page-purchase section.inactive-step img, body.page-purchase section.inactive-step div {
  float: left;
}
body.page-purchase section.inactive-step div.inactive-step-text {
  padding: 8px;
}
body.page-purchase img.icon-format {
  height: 30px;
  width: 30px;
}
body.page-purchase #insurance article label, body.page-purchase #crosssell article label, body.page-purchase #ticketformat article label, body.page-purchase #shipping article label, body.page-purchase #payment article label {
  cursor: pointer;
}
body.page-purchase .default-cursor {
  cursor: auto !important;
}
body.page-purchase .grid {
  width: 100%;
}
body.page-purchase .grid .half {
  width: 100%;
  float: left;
}
@media (min-width: 800px) {
  body.page-purchase a:hover, body.page-purchase button:hover {
    opacity: 0.9;
  }
  body.page-purchase .fixed {
    position: fixed;
  }
  body.page-purchase section {
    padding: 20px;
  }
  body.page-purchase #shipping hr, body.page-purchase #payment hr, body.page-purchase #ticketformat hr {
    margin-bottom: 20px;
  }
  body.page-purchase .grid .half {
    width: 50%;
    float: left;
  }
  body.page-purchase .right-column section, body.page-purchase .right-column > div {
    margin-left: 0;
  }
  body.page-purchase .checkout-notice .checkmark, body.page-purchase #headover .checkmark {
    left: 20px;
  }
  body.page-purchase .checkout-notice .arrow-right, body.page-purchase #headover .arrow-right {
    right: 20px;
  }
  body.page-purchase .checkout-notice .content {
    margin-left: 40px;
  }
  body.page-purchase #headover .content {
    margin-left: 10px;
  }
}
body.page-purchase .personalization-head {
  font-weight: 900;
}
body.page-purchase .personalization-listing {
  display: inline-block;
  color: #878988;
}
body.page-purchase #personalization-modal h3 {
  padding-right: 15px;
}
body.page-purchase .display-as-table {
  display: table;
}
body.page-purchase #payment-modal {
  width: 100%;
  height: 95%;
}
body.page-purchase #payment-modal #iframePayment {
  position: relative;
  width: 100%;
  height: 95%;
  overflow-x: scroll;
  overflow-y: scroll;
}
body.page-purchase .price-red {
  color: #f43752;
}
body.page-purchase .price-grey {
  text-decoration: line-through;
  color: #878988;
}
body.page-purchase .price-total-savings {
  float: right;
  color: #00964f;
}
body.page-purchase .event-information-headline {
  line-height: 25px;
}